import { Stack, Typography } from '@mui/material'
import { KisRequestHistory, KisRequestHistoryStatus, KisRequestHistoryType } from 'api/shipping'
import TsToFormatDate from 'util/TsToFormatDate'

export const kisRequestHistoryTypeMap: { [k in KisRequestHistoryType]: string } = {
  [KisRequestHistoryType.unloading]: 'Запрос данных по разгрузке в КИС'
}

export const kisRequestHistoryStatusMap: { [k in KisRequestHistoryStatus]: string } = {
  [KisRequestHistoryStatus.noAnswer]: 'Данные небыли получены',
  [KisRequestHistoryStatus.success]: 'Данные получены'
}

export default function KisRequestHistoryWizard ({ data: { requestTs, type, status } }: { data: KisRequestHistory}) {
  return (<Stack direction='row' alignItems='center' spacing={1}>
    <Typography fontSize='13px' fontWeight={600} >{TsToFormatDate(requestTs, 'dd.MM.yyyy HH:mm')}</Typography>
    <Typography fontSize='13px' fontWeight={500}>{kisRequestHistoryTypeMap[type]}. {kisRequestHistoryStatusMap[status]}</Typography>
  </Stack>)
}
