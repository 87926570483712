import { Shipping, ShippingTruckRun, ShippingTruckRunStatus } from 'api/shipping'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import './styles.sass'
import rub from 'util/formatRub'
import TripsCard from 'ui/TripsCard'
import formatCargo from 'util/formatCargo'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'
import statusToOrdering from 'common/TruckRuns/statusToOrdering'
import { Bid } from 'api/bid'

const statusMap: Map<ShippingTruckRunStatus, string> = new Map()
statusMap.set(ShippingTruckRunStatus.new, 'Отклики на рассмотрении')
statusMap.set(ShippingTruckRunStatus.confirmed, 'Подтвержденные отклики')
statusMap.set(ShippingTruckRunStatus.archiveReject, 'Отклоненные отклики')

const groupByStatusView = (s: ShippingTruckRunStatus) => statusMap.get(s) ?? s
interface Params {
  data: Shipping
  onChange?: () => void
  onViewQRCode?: (data: ShippingTruckRun) => void
  onRefuse?: (data: ShippingTruckRun & { bid: Omit<Bid, 'freeSpace'> }) => void
  groupByStatus?: boolean
}

export default function ShippingCard ({ data, onChange, groupByStatus, onViewQRCode, onRefuse }: Params) {
  return (
    <>
      <TripsCard
        data={data}
        descriptions={[
          { label: 'Груз', value: formatCargo(data.bid.cargo) },
          { label: 'Стоимость тн.', value: rub(data.bid.costTon) },
          { label: 'К вывозу', value: `${toTon(data.bid.totalWeight)} т` },
          { label: 'Расстояние', value: `${toKilometers(data.bid.distance)} км` },
          { label: 'Дата перевозки', value: `${formatDate(data.bid.beginTs)} - ${formatDate(data.bid.endTs)}` }
        ]}
        onChange={onChange}
        groupByStatus={groupByStatus}
        groupByStatusView={groupByStatusView}
        groupByStatusSort={(a, b) => statusToOrdering(a) - statusToOrdering(b)}
        onViewQRCode={onViewQRCode}
        onRefuse={onRefuse}
      />
    </>
  )
}
