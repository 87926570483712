import { Routes, Route } from 'react-router-dom'
import { IRoute } from './routes/types'

import { AuthLayout } from 'layouts/AuthLayout'
import { MainLayout } from 'layouts/MainLayout'

import { Login } from 'pages/Login'
import { AuthCode } from 'pages/AuthCode'

import Registration from 'pages/Registration'
import SuccessPreRegistration from 'pages/Registration/SuccessPreRegistration'

import PostRegistration from 'pages/PostRegistration'
import EndPage from 'pages/PostRegistration/EndPage'

import { AuthContextStatus, useAuthContext } from 'AuthContext'
import { PostRegistrationLayout } from 'layouts/PostRegistrationLayout'
import RecoveryPass from 'pages/RecoveryPass'
import { EventContextProvider } from 'EventContext'

export default function App () {
  const { status, routes } = useAuthContext()

  if (status === AuthContextStatus.waitLogin) {
    return <AuthLayout content={ <Login /> }/>
  } else if (status === AuthContextStatus.recovery) {
    return <AuthLayout content={ <RecoveryPass /> }/>
  } else if (status === AuthContextStatus.registration) {
    return <AuthLayout content={ <Registration /> }/>
  } else if (status === AuthContextStatus.waitCode) {
    return <AuthLayout content={ <AuthCode /> }/>
  } else if (status === AuthContextStatus.waitPostRegistration) {
    return <AuthLayout content={ <SuccessPreRegistration /> }/>
  } else if (status === AuthContextStatus.postRegistration) {
    return <PostRegistrationLayout content={ <PostRegistration /> } />
  } else if (status === AuthContextStatus.successRegistration) {
    return <PostRegistrationLayout content={ <EndPage /> } />
  } else if (status === AuthContextStatus.active) {
    return routes
      ? <EventContextProvider heartbeatInterval={10} reconnectInterval={3}>
          <Routes>
            <Route element={<MainLayout />}>
              { routes.map(({ path, element }: IRoute, idx) => <Route key={idx.toString()} path={path} element={element} />) }
            </Route>
          </Routes>
        </EventContextProvider>
      : <></>
  } else {
    return <></>
  }
}
