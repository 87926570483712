import './styles.sass'
// import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getNameByUserRole } from 'entity/User'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import DataTable from 'ui/DataTable'
// import Modal from 'ui/Modal'
// import ActionsMenu from 'ui/ActionsMenu'
// import { Archive, Edit } from '@mui/icons-material'
import { User, userList, UserRole } from 'api/user'
import { UserStatus } from 'api/UserStatus'
import ChipUserStatus from 'common/ChipUserStatus'
import NavigatePanel from 'ui/NavigatePanel'
import { isEnum } from 'util/isEnum'
import { useMainRoutes } from 'routes'

const tabs = new Map<UserRole, string>()
  .set(UserRole.administrator, 'Администраторы')
  .set(UserRole.chief_dispatcher, 'Старшие диспетчеры')
  .set(UserRole.dispatcher, 'Диспетчеры')
  .set(UserRole.chief_forwarder, 'Старшие экспедиторы')
  .set(UserRole.forwarder, 'Экспедиторы')
  .set(UserRole.carrier, 'Перевозчики')
  .set(UserRole.driver, 'Водители')

const DEFAULT_ROLE = UserRole.administrator

export default function Users () {
  // const [modalOpen, setModalOpen] = useState(false)
  // const [modalId, setModalId] = useState<string>()
  const [rows, setRows] = useState<User[]>()

  const { roles } = useParams()
  const filterRole = isEnum(UserRole)(roles) ? roles : DEFAULT_ROLE

  const { links } = useMainRoutes()
  const navigate = useNavigate()

  useEffect(() => {
    userList({ roles: filterRole === UserRole.administrator ? [UserRole.administrator, UserRole.root] : [filterRole] }).then(setRows)
  }, [filterRole])

  // const openModal = useCallback(async (id: string | undefined) => {
  //   setModalId(id)
  //   setModalOpen(true)
  // }, [])

  // const save = useCallback(async () => {
  //   setModalOpen(false)
  //   setRows(undefined)
  // }, [])

  return (
    <>
      <div className='users'>
        <NavigatePanel
          title='Пользователи'
          breadcrumbs={{
            items: [{ title: 'Пользователи' }]
          }}
          tabs={{
            items: [...tabs].map(([value, label]) => ({ label, value })),
            value: filterRole,
            onChange: value => navigate(links.USERS_PAGE + (value === DEFAULT_ROLE ? '' : `/${value}`)),
            scrollable: true
          }}
        />
        <div className='users__body'>
          <div className='users__body_table'>
            <DataTable
              columns = { [
                {
                  id: 'phone',
                  label: 'Телефон',
                  minWidth: 100
                },
                {
                  id: 'roles',
                  label: 'Роль в системе',
                  minWidth: 170,
                  format: (value) => <>{(value as UserRole[]).map(getNameByUserRole).join(', ')}</>
                },
                {
                  id: 'status',
                  label: 'Статус',
                  minWidth: 100,
                  format: (status) => <ChipUserStatus status={status as UserStatus} />
                },
                {
                  id: 'phone',
                  label: 'Логирование',
                  align: 'center',
                  minWidth: 10,
                  format: phone => <NavLink to='/logs' state={{ user: phone }}>История логов</NavLink>
                }
                // {
                //   id: 'id',
                //   label: '',
                //   align: 'center',
                //   minWidth: 10,
                //   format: value => <ActionsMenu key={`${value}`} actions = {[
                //     {
                //       caption: 'Редактировать',
                //       onClick: () => openModal(value as string),
                //       icon: <Edit />
                //     },
                //     {
                //       caption: 'Архивировать',
                //       onClick: () => {},
                //       icon: <Archive />
                //     }
                //   ]} />
                // }
              ] }
              rows = { (start, end) => rows === undefined ? [] : rows.slice(start, end) }
              rowsCount = { rows === undefined ? 0 : rows.length }
            />
          </div>
        </div>
        {/* <Modal
          title={modalId === undefined ? 'Добавить пользователя' : 'Редактировать пользователя'}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          content={ <>
            <Typography gutterBottom>
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
              consectetur ac, vestibulum at eros.
            </Typography>
            <Typography gutterBottom>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
            </Typography>
            <Typography gutterBottom>
              Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
              magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
              ullamcorper nulla non metus auctor fringilla.
            </Typography>
          </>}
          actions={<>
            <Button variant="outlined" onClick={() => setModalOpen(false)}>Отменить</Button>
            <Button variant="contained" onClick={ () => { save() }}>
              {modalId === undefined ? 'Добавить' : 'Сохранить'}
            </Button>
          </>}
        /> */}
      </div>
    </>
  )
}
