import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { Organization, OrganizationAll } from 'api/organization'
import { Passport, PassportForCreate } from 'api/passport'
import { UserStatus } from 'api/UserStatus'
import { UserRole } from './user'
import { RejectionExpand } from './rejection'
import { RequireOnly } from 'util/type/RequireOnly'
import { Customer } from './customer'

export interface Profile {
  firstName?: string
  secondName?: string
  familyName?: string
  email?: string
  organization: Organization | null
  passport: Passport | null
  archiveTs?: number
  archiveComment?: string
  rejection?: RejectionExpand
  contracts?: ProfileContract[]
  hasHiredDrivers?: boolean
  requests?: ProfileRequest[]
  preferredRegions?: string[]
}

export enum ProfileRequestStatus {
  new = 'new',
  accept = 'accept',
  cancel = 'cancel',
}

export enum ProfileRequestSlugs {
  addDrivers = 'add-drivers'
}
export interface ProfileRequest {
  id: Id
  status: ProfileRequestStatus
  slug: ProfileRequestSlugs
  createTs: number

  acceptTs?: number
  cancelTs?: number
  cancelComment?: string
  data?: Record<string, unknown>
}

export enum ProfileContractStatus {
  new = 'new',
  signed = 'signed',
}

export interface ProfileContract {
  status: ProfileContractStatus
  customer: RequireOnly<Customer, 'id' | 'fullName'>
  num: string
  createTs: number
  filename?: string
}

export interface ProfileContractCreate {
  customerId: Id
  num: string
  createTs: number
  filename?: string
}

export type ProfileForCreate = Omit<Profile, 'organization' | 'passport' | 'archiveTs' | 'archiveComment' | 'rejection' | 'contracts' | 'requests'> & {
  passport?: PassportForCreate
  organization?: Organization
}
export type ProfileForUpdate = ProfileForCreate

export interface ProfileAllEmployee {
  firstName: string
  secondName: string
  familyName: string
  role?: UserRole
}

export type ProfileAll = Omit<Profile, 'organization' | 'passport'> & {
  id: Id
  phone: string
  status: UserStatus
  createTs: number
  organization?: OrganizationAll
  truckRuns: Id[]
  verificationEmployee?: ProfileAllEmployee
  identificationEmployee?: ProfileAllEmployee
  identificationComment?: string
}

export type ProfileAllForCreate = ProfileForCreate & { phone: string }
export type ProfileAllForUpdate = ProfileForCreate & {
  id: Id
  verified?: boolean
  identifiedComment?: string
  identificationEmployeeRole?: UserRole
  contracts?: ProfileContractCreate[]
}

export interface ProfileAllFilter {
  role?: UserRole
  status?: UserStatus
}

export interface ProfileAllFilterList {
  role?: UserRole
  status?: UserStatus[]
}

export interface ProfileArchiveParams {
  id: Id
  date: number
  comment?: string
}

export interface ProfileContractCreateResult {
  success: boolean
  filename?: string
  conflicts?: {
    signedContract?: true
    documentCreateError?: true
  }
}

export interface ProfileContractSignDiadocParams {
  fromBoxId: string,
  customerId: string,
  content: string,
  signature: string,
}

const request = (method: string, params: object) => mainService.request(`profile.${method}`, params)

export const profileCreate = (params: ProfileForCreate = {}) => request('create', params) as Promise<{
  success: boolean
  conflicts?: {
    organizationExists?: boolean
  }
}>
export const profileUpdate = (params: ProfileForUpdate) => request('update', params) as Promise<boolean>
export const profileGet = () => request('get', {}) as Promise<Profile>

export const profileAllCreate = (params: ProfileAllForCreate) => request('all.create', params) as Promise<Id | null>
export const profileAllUpdate = (params: ProfileAllForUpdate) => request('all.update', params) as Promise<boolean>
export const profileAllGet = (id: Id, filter: ProfileAllFilter = {}) => request('all.get', { id, ...filter }) as Promise<ProfileAll | null>
export const profileAllList = (filter: ProfileAllFilterList = {}) => request('all.list', filter) as Promise<ProfileAll[]>
export const profileAllDelete = (id: Id) => request('all.delete', { id }) as Promise<boolean>
export const profileAllArchive = (params: ProfileArchiveParams) => request('all.archive', params) as Promise<boolean>

export const profileContractCreate = (customerId: Id) => request('contract.create', { customerId }) as Promise<ProfileContractCreateResult>
export const profileContractSignDiadoc = (params: ProfileContractSignDiadocParams) => request('contract.sign-diadoc', params) as Promise<void>
export const profileContractSign = (customerId: Id) => request('contract.sign', { customerId }) as Promise<void>

export const profileRequestAddDrivers = (params: { scanInsuredPersons?: string, scanReportEFS?: string, manningTable?: string }) =>
  request('request.add-drivers', params) as Promise<{
    success: boolean
    conflicts?: {
      exists?: boolean
    }
  }>

export const profileRequestAccept = (id: Id) => request('request.accept', { id }) as Promise<{ success: boolean }>
export const profileRequestCancel = (id: Id, comment?: string) => request('request.cancel', { id, comment }) as Promise<{ success: boolean }>

export const profileUpdatePreferredRegions = (preferredRegions: string[]) => request('update-regions', { preferredRegions }) as Promise<{ success: boolean }>
