import { Navigate } from 'react-router-dom'

import Home from '../pages/Home'
import CarrierBids from '../pages/CarrierBids'
import CarrierTrips from '../pages/CarrierTrips'
import CarrierDrivers from '../pages/CarrierDrivers'
import CarrierDriver from '../pages/CarrierDriver'
import CarrierVehicles from '../pages/CarrierVehicles'
import CarrierVehicle from '../pages/CarrierVehicle'
import CarrierTrailers from '../pages/CarrierTrailers'
import CarrierTrailer from '../pages/CarrierTrailer'
import Documents from '../pages/Documents'
import Users from '../pages/Users'
import Logs from '../pages/Logs'
import { Interface } from 'api/Interface'
import CarrierShipping from 'pages/CarrierShipping'
import CarrierBidPage from 'pages/CarrierBid'
import PointsAddress from 'pages/Registry/PointsAddress'
import Carriers from 'pages/Registry/Carriers'
import Forwarders from 'pages/Registry/Forwarders'
import Forwarder from 'pages/Registry/Forwarders/Forwarder'
import Carrier from 'pages/Registry/Carriers/Carrier'
import CarriersDrivers from 'pages/Registry/Carriers/Drivers'
import CarriersDriver from 'pages/Registry/Carriers/Driver'
import CarriersVehicles from 'pages/Registry/Carriers/Vehicles'
import CarriersVehicle from 'pages/Registry/Carriers/Vehicle'
import CarriersTrailers from 'pages/Registry/Carriers/Trailers'
import CarriersTrailer from 'pages/Registry/Carriers/Trailer'
import DispatcherBids from 'pages/DispatcherBids'
import Address from 'pages/Registry/PointsAddress/Address'
import CarrierActs from 'pages/CarrierActs'
import CarrierAct from 'pages/CarrierAct'
import { RoutesMap } from './RoutesMap'
import DispatcherBid from 'pages/DispatcherBid'
import DispatcherActs from 'pages/DispatcherActs'
import ForwarderProfile from 'pages/ForwarderProfile'
import ForwarderAttaches from 'pages/ForwarderAttaches'
import ForwarderLoads from 'pages/ForwarderLoads'
import ForwarderLoad from 'pages/ForwarderLoad'
import DispatcherRoutes from 'pages/Registry/Routes'
import Route from 'pages/Registry/Routes/Route'
import CarrierTrip from 'pages/CarrierTrip'
import AdminTruckRuns from 'pages/AdminTruckRuns'
import CarrierProfile from 'pages/CarrierProfile'
import CarrierHome from 'pages/CarrierHome'
import ForwarderHome from 'pages/ForwarderHome'
import {
  AltRoute,
  AssignmentReturned,
  ContactPhone,
  Dashboard,
  Grading,
  LocalShipping,
  MapOutlined,
  Person,
  People,
  Settings,
  TaskOutlined,
  UpgradeOutlined,
  VerticalSplit,
  Map,
  NearMe,
  Notifications,
  Restore,
  MarkChatRead,
  TextSnippet,
  CalendarMonth,
  Man2, RvHookup, Garage
} from '@mui/icons-material'
import Driver from 'ui/icon/Driver'
import CarrierShippingInfo from 'pages/CarrierShippingInfo'
import SystemSettings from 'pages/SystemSettings'
import Policy from 'pages/Policy'
import AdminCustomers from 'pages/AdminCustomers'
import AdminCustomer from 'pages/AdminCustomer'
import DispatcherHome from 'pages/DispatcherHome'
import DispatcherProfile from 'pages/DispatcherProfile'
import AdminBlackList from 'pages/AdminBlackList'
import History from 'pages/History'
import DispatcherShipping from 'pages/DispatcherShipping'
import DispatcherTrip from 'pages/DispatcherTrip'
import SystemNotifies from 'pages/SystemNotifies'
import DispatcherAct from 'pages/DispatcherAct'
import AdminConsignorsConsigness from 'pages/AdminConsignorsConsigness'
import AdminConsignorConsignee from 'pages/AdminConsignorConsignee'
import DispatcherResponses from 'pages/DispatcherResponses'
import DispatcherRegisters from 'pages/DispatcherRegisters'
import CarrierCalendar from 'pages/CarrierCalendar'
import TestPush from 'pages/TestPush'
import AdminSessions from 'pages/AdminSessions'
import RegistryDrivers from 'pages/Registry/Drivers'
import RegistryVehicles from 'pages/Registry/Vehicles'
import RegistryTrailers from 'pages/Registry/Trailers'
import AdminCargos from 'pages/AdminCargos'
import RegistryTruckRuns from 'pages/Registry/TruckRuns'

const links = {
  HOME_PAGE: '/home',
  CARRIER_BIDS_PAGE: '/carrier/bids',
  CARRIER_BIDS_SHIPPING_PAGE: '/carrier/bids/shipping',
  CARRIER_BIDS_SHIPPING_INFO_PAGE: '/carrier/bids/shipping/info',
  CARRIER_TRIPS_PAGE: '/carrier/trips',
  CARRIER_TRIPS_BID_PAGE: '/carrier/trips/bid',
  CARRIER_CALENDAR_PAGE: '/carrier/calendar',
  FORWARDER_LOADS_PAGE: '/forwarder/loads',
  FORWARDER_ATTACHES_PAGE: '/forwarder/attaches',
  CARRIER_DRIVERS_PAGE: '/carrier/drivers',
  CARRIER_VEHICLES_PAGE: '/carrier/vehicles',
  CARRIER_TRAILERS_PAGE: '/carrier/trailers',
  DOCUMENTS_PAGE: '/documents',
  CARRIER_DOCUMENTS_ACTS_PAGE: '/carrier/documents',
  CARRIER_DOCUMENTS_ACT_PAGE: '/carrier/document',
  REGISTRY_PAGE: '/registry',
  REGISTRY_POINTS_ADDRESS_PAGE: '/registry/points-address',
  REGISTRY_CARRIER_PAGE: '/registry/carrier',
  REGISTRY_CARRIERS_PAGE: '/registry/carriers',
  REGISTRY_FORWARDER_PAGE: '/registry/forwarder',
  REGISTRY_FORWARDERS_PAGE: '/registry/forwarders',
  REGISTRY_DRIVERS_PAGE: '/registry/drivers',
  REGISTRY_VEHICLES_PAGE: '/registry/vehicles',
  REGISTRY_TRAILERS_PAGE: '/registry/trailers',
  REGISTRY_TRUCK_RUNS_PAGE: '/registry/truck-runs',
  REGISTRY_ROUTES_PAGE: '/registry/routes',
  DISPATCHER_BIDS_PAGE: '/dispatcher/bids',
  DISPATCHER_BID_PAGE: '/dispatcher/bid',
  DISPATCHER_DOCUMENTS_ACTS_PAGE: '/dispatcher/documents',
  DISPATCHER_DOCUMENTS_ACT_PAGE: '/dispatcher/document',
  DISPATCHER_DOCUMENTS_INVOICES_PAGE: '/dispatcher/documents/invoices',
  DISPATCHER_SHIPPING_PAGE: '/dispatcher/shipping',
  DISPATCHER_TRIPS_PAGE: '/dispatcher/trips',
  DISPATCHER_REGISTERS_PAGE: '/dispatcher/registers',
  DISPATCHER_RESPONSES: '/dispatcher/responses',
  USERS_PAGE: '/users',
  LOGS_PAGE: '/logs',
  PROFILE_PAGE: '/profile',
  ADMIN_TRUCK_RUNS: '/admin/truck-runs',
  ADMIN_SYSTEM_SETTINGS: '/admin/system-settings',
  ADMIN_CARGOS: '/admin/cargos',
  POLICY_PERSONAL_DATA_PAGE: '/policy-personal-data',
  ADMIN_CUSTOMERS_PAGE: '/admin/customers',
  ADMIN_CONSIGNOR_CONSIGNEE_PAGE: '/admin/consignor-consignee',
  ADMIN_BLACKLIST_PAGE: '/admin/black-list',
  ADMIN_SESSIONS_PAGE: '/admin/sessions',
  HISTORY_PAGE: '/history',
  NOTIFICATIONS: '/notifications'
} as const

const routesMap = new RoutesMap([
  {
    path: links.HOME_PAGE,
    element: <Home />,
    // title: 'Главная',
    icon: <Dashboard />,
    // group: 1,
    interfaces: [
      Interface.administrator
    ]
  },
  {
    path: links.HOME_PAGE,
    element: <CarrierHome />,
    title: 'Главная',
    icon: <Dashboard />,
    group: 1,
    interfaces: [Interface.carrier]
  },
  {
    path: links.HOME_PAGE,
    element: <ForwarderHome />,
    title: 'Главная',
    icon: <Dashboard />,
    group: 1,
    interfaces: [
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: links.HOME_PAGE,
    element: <DispatcherHome />,
    title: 'Главная',
    icon: <Dashboard />,
    group: 1,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.CARRIER_BIDS_PAGE,
    element: <CarrierBids />,
    title: 'Заявки',
    icon: <VerticalSplit />,
    group: 1,
    interfaces: [Interface.carrier],
    breadcrumbs: [
      { title: 'Заявки', to: links.CARRIER_BIDS_PAGE }
    ]
  },
  {
    path: `${links.CARRIER_BIDS_PAGE}/:id`,
    element: <CarrierBidPage />,
    interfaces: [Interface.carrier]
  },
  {
    path: `${links.CARRIER_TRIPS_BID_PAGE}/:id`,
    element: <CarrierBidPage />,
    interfaces: [Interface.carrier]
  },
  {
    path: `${links.CARRIER_BIDS_SHIPPING_PAGE}/:status`,
    element: <CarrierShipping />,
    interfaces: [Interface.carrier]
  },
  {
    path: `${links.CARRIER_BIDS_SHIPPING_INFO_PAGE}/:id`,
    element: <CarrierShippingInfo />,
    interfaces: [Interface.carrier]
  },
  {
    path: links.CARRIER_TRIPS_PAGE,
    element: <CarrierTrips />,
    title: 'Рейсы',
    icon: <MapOutlined />,
    group: 1,
    interfaces: [Interface.carrier],
    breadcrumbs: [
      { title: 'Рейсы', to: links.CARRIER_TRIPS_PAGE }
    ]
  },
  {
    path: `${links.CARRIER_TRIPS_PAGE}/:id`,
    element: <CarrierTrip />,
    interfaces: [
      Interface.carrier,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.CARRIER_CALENDAR_PAGE,
    element: <CarrierCalendar />,
    title: 'Календарь',
    icon: <CalendarMonth />,
    group: 1,
    interfaces: [Interface.carrier],
    breadcrumbs: [
      { title: 'Календарь', to: links.CARRIER_TRIPS_PAGE }
    ]
  },
  {
    path: links.FORWARDER_LOADS_PAGE,
    element: <ForwarderLoads />,
    title: 'Погрузки',
    icon: <Map />,
    group: 1,
    interfaces: [
      Interface.chief_forwarder,
      Interface.forwarder
    ],
    breadcrumbs: [
      { title: 'Погрузки', to: links.FORWARDER_LOADS_PAGE }
    ]
  },
  {
    path: `${links.FORWARDER_LOADS_PAGE}/:id`,
    element: <ForwarderLoad />,
    interfaces: [
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: links.CARRIER_DRIVERS_PAGE,
    element: <CarrierDrivers />,
    title: 'Водители',
    interfaces: [Interface.carrier],
    icon: <Driver />,
    group: 3,
    breadcrumbs: [
      { title: 'Водители', to: links.CARRIER_DRIVERS_PAGE }
    ]
  },
  {
    path: `${links.CARRIER_DRIVERS_PAGE}/:id`,
    element: <CarrierDriver />,
    interfaces: [Interface.carrier]
  },
  {
    path: links.CARRIER_VEHICLES_PAGE,
    element: <CarrierVehicles />,
    title: 'Транспорт',
    icon: <LocalShipping />,
    group: 3,
    interfaces: [Interface.carrier],
    breadcrumbs: [
      { title: 'Транспорт', to: links.CARRIER_VEHICLES_PAGE }
    ]
  },
  {
    path: `${links.CARRIER_VEHICLES_PAGE}/:id`,
    element: <CarrierVehicle />,
    interfaces: [Interface.carrier]
  },
  {
    path: links.CARRIER_TRAILERS_PAGE,
    element: <CarrierTrailers />,
    title: 'Прицепы',
    icon: <UpgradeOutlined />,
    group: 3,
    interfaces: [Interface.carrier],
    breadcrumbs: [
      { title: 'Прицепы', to: links.CARRIER_TRAILERS_PAGE }
    ]
  },
  {
    path: `${links.CARRIER_TRAILERS_PAGE}/:id`,
    element: <CarrierTrailer />,
    interfaces: [Interface.carrier]
  },
  {
    path: links.DOCUMENTS_PAGE,
    element: <Documents />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },

  {
    path: links.CARRIER_DOCUMENTS_ACTS_PAGE,
    element: <CarrierActs />,
    title: 'Документы',
    icon: <TaskOutlined />,
    group: 2,
    breadcrumbs: [
      { title: 'Документы', to: links.CARRIER_DOCUMENTS_ACTS_PAGE }
    ],
    interfaces: [Interface.carrier]
  },
  {
    path: links.CARRIER_DOCUMENTS_ACTS_PAGE + '/:status',
    element: <CarrierActs />,
    interfaces: [Interface.carrier]
  },

  {
    path: `${links.CARRIER_DOCUMENTS_ACT_PAGE}/:id`,
    element: <CarrierAct />,
    interfaces: [Interface.carrier]
  },
  {
    path: links.DISPATCHER_DOCUMENTS_ACTS_PAGE,
    element: <DispatcherActs />,
    title: 'Документы',
    icon: <TaskOutlined />,
    group: 2,
    breadcrumbs: [
      { title: 'Документы', to: links.DISPATCHER_DOCUMENTS_ACTS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.DISPATCHER_REGISTERS_PAGE,
    element: <DispatcherRegisters />,
    icon: <TextSnippet />,
    group: 2,
    breadcrumbs: [
      { title: 'Реестры', to: links.DISPATCHER_REGISTERS_PAGE }
    ],
    title: 'Реестры',
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: `${links.DISPATCHER_DOCUMENTS_ACTS_PAGE}/:status`,
    element: <DispatcherActs />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: `${links.DISPATCHER_DOCUMENTS_ACT_PAGE}/:id`,
    element: <DispatcherAct />,
    interfaces: [
      Interface.chief_dispatcher
    ]
  },
  {
    path: links.REGISTRY_POINTS_ADDRESS_PAGE,
    element: <PointsAddress />,
    title: <>Элеваторы<br />и хозяйства</>,
    icon: <AssignmentReturned />,
    group: 3,
    ordering: 80,
    breadcrumbs: [
      { title: 'Элеваторы и хозяйства', to: links.REGISTRY_POINTS_ADDRESS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_POINTS_ADDRESS_PAGE}/:id`,
    element: <Address />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: links.REGISTRY_CARRIERS_PAGE,
    element: <Carriers />,
    title: 'Перевозчики',
    icon: <Driver />,
    group: 3,
    ordering: 100,
    breadcrumbs: [
      { title: 'Перевозчики', to: links.REGISTRY_CARRIERS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIERS_PAGE}/:status`,
    element: <Carriers />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:id`,
    element: <Carrier />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/drivers`,
    element: <CarriersDrivers />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/drivers/:driverId`,
    element: <CarriersDriver />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/vehicles`,
    element: <CarriersVehicles />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/vehicles/:vehicleId`,
    element: <CarriersVehicle />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/trailers`,
    element: <CarriersTrailers />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.REGISTRY_CARRIER_PAGE}/:carrier/trailers/:trailerId`,
    element: <CarriersTrailer />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: links.REGISTRY_DRIVERS_PAGE,
    element: <RegistryDrivers />,
    title: 'Водители',
    icon: <Man2 />,
    group: 3,
    ordering: 100,
    breadcrumbs: [
      { title: 'Водители', to: links.REGISTRY_DRIVERS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_DRIVERS_PAGE + '/:status',
    element: <RegistryDrivers />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_VEHICLES_PAGE,
    element: <RegistryVehicles />,
    title: 'Транспорт',
    icon: <LocalShipping />,
    group: 3,
    ordering: 100,
    breadcrumbs: [
      { title: 'Транспорт', to: links.REGISTRY_VEHICLES_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_VEHICLES_PAGE + '/:status',
    element: <RegistryVehicles />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_TRAILERS_PAGE,
    element: <RegistryTrailers />,
    title: 'Прицепы',
    icon: <RvHookup />,
    group: 3,
    ordering: 100,
    breadcrumbs: [
      { title: 'Прицепы', to: links.REGISTRY_TRAILERS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_TRAILERS_PAGE + '/:status',
    element: <RegistryTrailers />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_TRUCK_RUNS_PAGE,
    element: <RegistryTruckRuns />,
    title: 'Рейсы',
    icon: <Garage />,
    group: 3,
    ordering: 100,
    breadcrumbs: [
      { title: 'Рейсы', to: links.REGISTRY_TRUCK_RUNS_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_TRUCK_RUNS_PAGE + '/:status',
    element: <RegistryTruckRuns />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_FORWARDERS_PAGE,
    element: <Forwarders />,
    title: 'Экспедиторы',
    icon: <ContactPhone />,
    group: 3,
    ordering: 90,
    breadcrumbs: [
      { title: 'Экспедиторы', to: links.REGISTRY_FORWARDERS_PAGE }
    ],
    interfaces: [
      Interface.chief_forwarder,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: `${links.REGISTRY_FORWARDERS_PAGE}/:status`,
    element: <Forwarders />,
    interfaces: [
      Interface.chief_forwarder,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.FORWARDER_ATTACHES_PAGE,
    element: <ForwarderAttaches />,
    title: 'Закрепления',
    icon: <ContactPhone />,
    group: 1,
    interfaces: [
      Interface.chief_forwarder,
      Interface.forwarder
    ],
    breadcrumbs: [
      { title: 'Закрепления' }
    ]
  },
  {
    path: `${links.REGISTRY_FORWARDER_PAGE}/:id`,
    element: <Forwarder />,
    interfaces: [
      Interface.chief_forwarder,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.DISPATCHER_BIDS_PAGE,
    element: <DispatcherBids />,
    title: 'Заявки',
    icon: <VerticalSplit />,
    group: 1,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ],
    breadcrumbs: [
      { title: 'Заявки', to: links.CARRIER_BIDS_PAGE }
    ]
  },
  {
    path: `${links.DISPATCHER_BIDS_PAGE}/:tab`,
    element: <DispatcherBids />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.DISPATCHER_BID_PAGE,
    element: <DispatcherBid />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: `${links.DISPATCHER_BID_PAGE}/:bidId`,
    element: <DispatcherBid />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.DISPATCHER_RESPONSES,
    element: <DispatcherResponses />,
    title: 'Отклики',
    icon: <MarkChatRead />,
    group: 1,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ],
    breadcrumbs: [
      { title: 'Отклики' }
    ]
  },
  {
    path: `${links.DISPATCHER_RESPONSES}/:view`,
    element: <DispatcherResponses />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.DISPATCHER_SHIPPING_PAGE,
    element: <DispatcherShipping />,
    title: 'Перевозки',
    icon: <NearMe />,
    group: 1,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ],
    breadcrumbs: [
      { title: 'Перевозки', to: links.DISPATCHER_SHIPPING_PAGE }
    ]
  },
  {
    path: `${links.DISPATCHER_TRIPS_PAGE}/:id`,
    element: <DispatcherTrip />,
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.REGISTRY_ROUTES_PAGE,
    element: <DispatcherRoutes />,
    title: 'Маршруты',
    icon: <AltRoute />,
    group: 3,
    ordering: 60,
    breadcrumbs: [
      { title: 'Маршруты', to: links.REGISTRY_ROUTES_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: `${links.REGISTRY_ROUTES_PAGE}/:routeId`,
    element: <Route />,
    breadcrumbs: [
      { title: 'Маршруты', to: links.REGISTRY_ROUTES_PAGE }
    ],
    interfaces: [
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.USERS_PAGE,
    element: <Users />,
    title: 'Пользователи',
    icon: <People />,
    interfaces: [Interface.administrator]
  },
  {
    path: links.ADMIN_SESSIONS_PAGE,
    element: <AdminSessions />,
    title: 'Сессии',
    icon: <People />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.USERS_PAGE}/:roles`,
    element: <Users />,
    interfaces: [Interface.administrator]
  },
  {
    path: links.LOGS_PAGE,
    element: <Logs />,
    // title: 'Логи',
    icon: <Grading />,
    interfaces: [Interface.administrator],
    breadcrumbs: [
      { title: 'Пользователи', to: links.USERS_PAGE }
    ]
  },
  {
    path: links.NOTIFICATIONS,
    element: <SystemNotifies />,
    group: 'bottom',
    ordering: 100,
    title: 'Уведомления',
    icon: <Notifications />,
    interfaces: [
      Interface.carrier,
      Interface.forwarder,
      Interface.chief_forwarder,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.PROFILE_PAGE,
    element: <CarrierProfile />,
    title: 'Профиль',
    icon: <Person />,
    group: 'bottom',
    interfaces: [Interface.carrier]
  },
  {
    path: links.PROFILE_PAGE,
    element: <DispatcherProfile />,
    title: 'Профиль',
    icon: <Person />,
    group: 'bottom',
    interfaces: [
      Interface.administrator,
      Interface.chief_dispatcher,
      Interface.dispatcher
    ]
  },
  {
    path: links.PROFILE_PAGE,
    element: <ForwarderProfile />,
    title: 'Профиль',
    icon: <Person />,
    group: 'bottom',
    interfaces: [
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.ADMIN_TRUCK_RUNS}`,
    element: <AdminTruckRuns />,
    interfaces: [
      Interface.administrator,
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.chief_forwarder,
      Interface.forwarder
    ]
  },
  {
    path: `${links.ADMIN_SYSTEM_SETTINGS}`,
    title: 'Настройки',
    icon: <Settings />,
    element: <SystemSettings />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.ADMIN_CARGOS}`,
    title: 'Грузы',
    icon: <LocalShipping />,
    element: <AdminCargos />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.POLICY_PERSONAL_DATA_PAGE}`,
    element: <Policy height='100vh' />,
    interfaces: [
      Interface.administrator,
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.carrier,
      Interface.forwarder,
      Interface.driver
    ]
  },
  {
    path: `${links.ADMIN_CUSTOMERS_PAGE}`,
    element: <AdminCustomers />,
    title: 'Заказчики',
    icon: <People />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.ADMIN_CUSTOMERS_PAGE}/:id`,
    element: <AdminCustomer />,
    interfaces: [Interface.administrator]
  },
  {
    path: links.ADMIN_CONSIGNOR_CONSIGNEE_PAGE,
    element: <AdminConsignorsConsigness />,
    title: 'Организации',
    icon: <People />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.ADMIN_CONSIGNOR_CONSIGNEE_PAGE}/:id`,
    element: <AdminConsignorConsignee />,
    interfaces: [Interface.administrator]
  },
  {
    path: links.ADMIN_BLACKLIST_PAGE,
    element: <AdminBlackList />,
    title: 'Черный список',
    icon: <People />,
    interfaces: [Interface.administrator]
  },
  {
    path: `${links.ADMIN_BLACKLIST_PAGE}/:type`,
    element: <AdminBlackList />,
    interfaces: [Interface.administrator]
  },
  {
    path: links.HISTORY_PAGE,
    element: <History />,
    title: 'История изменений',
    icon: <Restore />,
    interfaces: [Interface.administrator]
  },
  {
    path: 'test_push',
    element: <TestPush />,
    interfaces: [
      Interface.administrator,
      Interface.chief_dispatcher,
      Interface.dispatcher,
      Interface.carrier,
      Interface.forwarder,
      Interface.driver
    ]
  }
], {
  path: '*',
  element: <Navigate to={links.HOME_PAGE} />
}, {
  path: '*',
  element: <Navigate to={links.PROFILE_PAGE} />
})

export function useMainRoutes () {
  return {
    links,
    routesMap
  }
}
