import './styles.sass'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { ShippingTruckRunExtBid, shippingAllGet, ShippingTruckRunStatus, shippingTruckRunChangeStatus, ShippingTruckRunLoad } from 'api/shipping'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { Id, isId } from 'api/Id'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Close, Edit } from '@mui/icons-material'
import InfoCard, { InfoItemParams } from 'ui/InfoCard'
import Map from 'ui/Osm'
import TsToFormatDate from 'util/TsToFormatDate'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import FullAddress from 'ui/FullAddress'
import ChipStatus from 'ui/ChipStatus'
import formatPhone from 'util/formatPhone'
// import ForwarderLoadConfirm from 'pages/ForwarderLoad/ForwarderLoadConfirm'
import { organizationAllList } from 'api/organization'
import rub from 'util/formatRub'
import formateParties from 'util/formateParties'
import formatCargo from 'util/formatCargo'
import ForwarderLoadConfirmModal from 'pages/ForwarderLoads/ForwarderLoadConfirmModal'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'
import { useAuthContext } from 'AuthContext'
import { today as getToday } from 'util/date'
import TruckRunEditModel from 'ui/TruckRunEditModel'
import formatHeightRestriction from 'util/formatHeightRestriction'
import InfoIcon from '@mui/icons-material/Info'
import { BidCargo, bidCargoList } from 'api/bid'

const today = getToday()

type Item = ShippingTruckRunExtBid & { carrierName: string }

const getInfo = (data: Item) => {
  const result: InfoItemParams[] = []
  const statusLabel = 'Статус погрузки'

  if (data.status === ShippingTruckRunStatus.confirmed) {
    result.push({ label: statusLabel, value: <ChipStatus label='Ожидаемый' color='grey' /> })
  } else if (data.status === ShippingTruckRunStatus.archiveOutdated) {
    result.push({ label: statusLabel, value: <ChipStatus label='Просрочен' color='red' /> })
  } else if (data.status === ShippingTruckRunStatus.way) {
    result.push({ label: statusLabel, value: <ChipStatus label='Погружено' color='blue' /> })
  } else {
    result.push({ label: statusLabel, value: <ChipStatus label='Завершенный' color='green' /> })
  }

  result.push(
    { label: 'Тип ТС:', value: data.bid.typeVehicle.length === 0 ? 'Любой' : data.bid.typeVehicle.map(item => item.name).join(', ') },
    { label: 'Дата погрузки:', value: TsToFormatDate(data.actualLoadingTs ?? data.loadingTs, 'dd MMMM yyyy') },
    { label: 'Общий вес перевозки, тн:', value: `${toTon(data.bid.totalWeight)} тн` },
    { label: 'Стоимость груза за 1 тн:', value: rub(data.bid.costCargo) },
    { label: 'Груз:', value: formatCargo(data.bid.cargo) },
    { label: 'Расстояние:', value: `${toKilometers(data.bid.distance)} км` },
    { label: 'Стоимость перевозки тн/км:', value: rub(data.bid.costTonKm) },
    { label: 'Cтоимость перевозки 1 тн:', value: rub(data.bid.costTon) }
  )

  !!data.billTs && result.push({ label: 'Дата ТрН/ТТН', value: TsToFormatDate(data.billTs, 'dd MMMM yyyy') })
  !!data.billNumber && result.push({ label: 'Номер ТрН/ТТН', value: `${data.billNumber}` })
  !!data.weight && result.push({ label: 'Вес погрузки, тн', value: `${toTon(data.weight)} тн` })

  result.push(
    { label: 'Заказчик перевозки:', value: data.bid.customer.fullName },
    { label: 'Грузим +10%:', value: data.bid.plusTen ? 'Да' : 'Нет' },
    { label: 'Перевозчик:', value: data.carrierName },
    { label: 'ТС:', value: vehicleName(data.vehicle) },
    { label: 'Прицеп:', value: trailerName(data.trailer) },
    { label: 'Водитель:', value: personName(data.driver) },
    { label: 'Номер телефона:', value: formatPhone(data.driver.phone) }
  )

  return result
}

export default function ForwarderLoad () {
  const [data, setData] = useState<Item | null>()
  const { id } = useParams()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const [loadConfrim, setLoadConfrim] = useState<Partial<ShippingTruckRunLoad>>()
  const { handleResponseFailure, currentInterface } = useAuthContext()
  const [editId, setEditId] = useState<Id>()
  const [cargoList, setCargoList] = useState<BidCargo[]>([])

  const title = useMemo(
    () => `Погрузка по перевозке ${data?.bid.num} от ${TsToFormatDate(data?.loadingTs, 'dd.MM')}`,
    [data]
  )

  const init = useCallback(async () => {
    bidCargoList().then(setCargoList)
    const result = isId(id) ? await shippingAllGet(id) : null

    if (result === null) {
      setData(null)
    } else {
      const orgs = await organizationAllList({ owner: [result.owner] })

      setData({
        ...result,
        carrierName: (orgs.length > 0 ? orgs[0].shortName : undefined) ?? result.owner
      })
    }
  }, [id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const handleBack = () => {
    navigate(-1)
  }

  const handleCloseConfrimModel = () => {
    setLoadConfrim(undefined)
  }

  const confirm = async (id: Id, weight: number, billNumber: string, billTs: number, actualCargoSlug: string) => {
    if (!isId(id)) {
      return
    }

    const { success, conflicts } = await shippingTruckRunChangeStatus({ list: [{ id, status: ShippingTruckRunStatus.way, weight, billNumber, billTs, actualCargoSlug }] })

    if (!success && conflicts?.toWay) {
      handleResponseFailure(`Рейс ${conflicts.truckRun.num} уже в пути`)
    }

    init()
    handleCloseConfrimModel()
  }

  if (data === undefined) {
    return <></>
  }

  if (data === null) {
    return <Navigate to={links.HOME_PAGE} />
  }

  // TODO
  // Раскомментировать  перед презентацией связки ролей Диспетчер, Перевозчик, Экспедитор
  // const { loadingTs } = data
  // const day = loadingTs - (loadingTs % DAY)
  const day = today

  return (
    <>
      <div className='forwarderLoad'>
        <div className='forwarderLoad_content'>
          <NavigatePanel
            breadcrumbs={{
              defaultItems: routesMap.getBreadcrumbs(links.FORWARDER_LOADS_PAGE),
              items: [{ title }]
            }}
            title={title}
            actions={<Stack direction='row' justifyContent='flex-end'>
              { data.status === ShippingTruckRunStatus.way && currentInterface && <IconButton onClick={() => isId(id) && setEditId(id)}>
                <Edit />
              </IconButton> }
              <IconButton onClick={() => handleBack()}>
                <Close />
              </IconButton>
            </Stack>}
          />
          <div className='forwarderLoad_content_info'>
            {/* { data.status === ShippingTruckRunStatus.confirmed && day === today && <InfoCard className='forwarderLoad_content_infoCard'
              key='confirm'
              title='Укажите детали погрузки'
            >
              <ForwarderLoadConfirm onConfirm={async (weight: number, billNumber: string) => {
                if (!isId(id)) {
                  return
                }

                await shippingTruckRunChangeStatus({ list: [{ id, status: ShippingTruckRunStatus.way, weight, billNumber }] })
                init()
              }}/>
            </InfoCard>} */}
            { [ShippingTruckRunStatus.confirmed, ShippingTruckRunStatus.archiveOutdated].includes(data.status) && day === today && <InfoCard className='forwarderLoad_content_infoCard'
              key='confirm'
            >
              <>
                <Button variant='contained' onClick={() => setLoadConfrim({ id: data.id, cargo: data.bid.cargo })} >Отправить рейс</Button>
              </>
            </InfoCard>}
            <InfoCard className='forwarderLoad_content_infoCard'
              key='route'
              title={<Stack direction='row' alignItems='center'>
                <Box>Маршрут</Box>
                { data.unloadingAddress && <Box sx={{ color: '#ffa800', mt: 0.5, ml: 1 }}><InfoIcon /></Box> }
              </Stack>}
              items={[
                { label: 'Пункт погрузки', value: <FullAddress address={data.bid.loadingAddress} /> },
                {
                  label: 'Пункт разгрузки',
                  value: data.unloadingAddress
                    ? <>
                      <Box><FullAddress address={data.unloadingAddress} /></Box>
                      <Box sx={{ color: '#B2B2B2', mt: 1 }}><FullAddress address={data.bid.unloadingAddress} /></Box>
                    </>
                    : <FullAddress address={data.bid.unloadingAddress} />
                }
              ]}
            />
            <InfoCard className='forwarderLoad_content_infoCard'
              key='info'
              title='Информация о перевозке'
              items={getInfo(data)}
            />
            <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте погрузки'
                items={[
                  { label: 'Способ погрузки:', value: data.bid.loadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.loadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.loadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.loadingWeighing) },
                  { label: 'Грузоотправитель', value: formateParties(data.bid.consignor) }
                ]} />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте разгрузки'
                items={[
                  { label: 'Способ разгрузки:', value: data.bid.unloadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.unloadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.unloadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.unloadingWeighing) },
                  { label: 'Грузополучатель', value: formateParties(data.consignee ?? data.bid.consignee) }
                ]}
              />
          </div>
        </div>
        <div className='forwarderLoad_map'>
        { data && <Map route={{ from: data.bid.loadingAddress.coordinates, to: (data.unloadingAddress ?? data.bid.unloadingAddress).coordinates }} /> }
        </div>
      </div>
      <ForwarderLoadConfirmModal
        cargoList={cargoList}
        truckRun={loadConfrim}
        onClose={handleCloseConfrimModel}
        onConfirm={confirm}
      />
      <TruckRunEditModel
        cargoList={cargoList}
        id={editId}
        onClose={() => setEditId(undefined)}
        onSave={init}
        forwarder
      />
    </>
  )
}
