import { ShippingTruckRunStatus } from 'api/shipping'
import { ReactNode } from 'react'
import ChipStatus from 'ui/ChipStatus'

const ChipTruckRunStatus = (status: ShippingTruckRunStatus, label?: ReactNode, style?: React.CSSProperties) => {
  switch (status) {
    case ShippingTruckRunStatus.new:
      return <ChipStatus label={label || 'На рассмотрении'} style={style} />
    case ShippingTruckRunStatus.confirmed:
      return <ChipStatus label={label || 'Подтвержден'} color='green' style={style} />
    case ShippingTruckRunStatus.way:
      return <ChipStatus label={label || 'В пути'} style={style} />
    case ShippingTruckRunStatus.arrived:
      return <ChipStatus label={label || 'Выгружен'} color='yellow' style={style} />
    case ShippingTruckRunStatus.completed:
      return <ChipStatus label={label || 'Завершен'} color='green' style={style} />
    case ShippingTruckRunStatus.archiveReject:
      return <ChipStatus label={label || 'Отклонен'} color='red' style={style} />
    case ShippingTruckRunStatus.archiveAct:
      return <ChipStatus label={label || 'Архив'} style={style} />
    case ShippingTruckRunStatus.archiveRefuse:
      return <ChipStatus label={label || 'Отозван'} style={style} />
    case ShippingTruckRunStatus.archiveOutdated:
      return <ChipStatus label={label || 'Просрочен'} color='red' style={style} />
    case ShippingTruckRunStatus.archiveProblem:
      return <ChipStatus label={label || 'Завершен диспетчером'} color='red' style={style} />
    default:
      return <></>
  }
}

export default ChipTruckRunStatus
