import './styles.sass'
import Modal from 'ui/Modal'
import { Box } from '@mui/material'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import TsToFormatDate from 'util/TsToFormatDate'
import { Shippings } from '..'
import formatCargo from 'util/formatCargo'
import rub from 'util/formatRub'
import { toKilometers } from 'util/distance'
import TripsCard from 'ui/TripsCard'

export interface RespondModalParams {
  onClose: () => void
  day?: number
  data?: Shippings
}

export default function DayModal ({ onClose, day, data }: RespondModalParams) {
  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Modal
        maxWidth={false}
        open={day !== undefined && data !== undefined}
        onClose={handleClose}
        title={day !== undefined ? TsToFormatDate(day, 'dd MMMM yyyy') : ''}
        content={<Box minWidth='500px' display='flex' flexDirection='column' gap={1}>
        { data && <>{
          data.map((item) => <TripsCard
            data={item}
            descriptions={[
              { label: 'Груз', value: formatCargo(item.bid.cargo) },
              { label: 'Стоимость тн.', value: rub(item.bid.costTon) },
              { label: 'Расстояние', value: `${toKilometers(item.bid.distance)} км` }
            ]}
            key={`day-modal-${item.bid.id}`}
            statusView={ChipTruckRunStatus}
            shortAddress
          />)
        }</> }
        </Box>}
        actions={<></>}
      />
    </>
  )
}
