import { authService } from 'api/service'
import { RequireOnly } from 'util/type/RequireOnly'
import { UserStatus } from 'api/UserStatus'

export enum UserRole {
  root = 'root',
  administrator = 'administrator',
  carrier = 'carrier',
  driver = 'driver',
  chief_dispatcher = 'chief_dispatcher',
  dispatcher = 'dispatcher',
  forwarder = 'forwarder',
  chief_forwarder = 'chief_forwarder',
}

export interface User {
  phone: string
  roles: UserRole[]
  status: UserStatus
  extraPermits: Record<string, string[]>
}

type ForUpdate = User & { password: string }

export type UserForCreate = RequireOnly<ForUpdate, 'phone' | 'roles' | 'password'>
export type UserForUpdate = RequireOnly<ForUpdate, 'phone'>
export type UserFilter = {
  roles?: UserRole[],
  status?: UserStatus[]
}

const request = (method: string, params: object) => authService.request(`user.${method}`, params)

export const userCreate = (params: UserForCreate) => request('create', params) as Promise<boolean>
export const userUpdate = (params: UserForUpdate) => request('update', params) as Promise<boolean>
export const userExists = (phone: string) => request('exists', { phone }) as Promise<boolean>
export const userGet = (phone: string) => request('get', { phone }) as Promise<User | null>
export const userList = (params: UserFilter = {}) => request('list', params) as Promise<User[]>
export const userDelete = (phone: string) => request('delete', { phone }) as Promise<boolean>
export const userUpdatePass = (oldPassword: string, newPassword: string) => request('update-pass', { oldPassword, newPassword }) as Promise<boolean>
export const userRecoveryPass = (newPassword: string) => request('recovery-pass', { newPassword }) as Promise<boolean>
export const userSetRegistered = () => request('set-registered', {}) as Promise<boolean>
